import { ProfileInformationData, SearchPageAttribute } from '@/shared/lib-api';
import { useAppSelector } from '@/shared/store/hooks';
import { useValidationSchemes } from '@/web/util/hooks/validation-scheme-hook';
import { Box, Grid } from '@mui/material';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { object, SchemaOf, string } from 'yup';
import SubHeaderInputField from '../../default-components/input/formik/sub-header-input-field';

const countryCodes: SearchPageAttribute[] = [
  { value: '+45', label: '+45' }, //DK
  { value: '+46', label: '+46' }, //SE
  { value: '+49', label: '+49' }, //DE
  { value: '+31', label: '+31' }, //NL
  { value: '+47', label: '+47' }, //NO
];

export const ProfileInformationForm: React.FC<{
  onSubmit: (values: ProfileInformationData) => Promise<void>;
  children?: React.ReactNode;
}> = ({ onSubmit, children }) => {
  const { t } = useTranslation();
  const personalInfo = useAppSelector(
    state => state.auth.cachedUser.personalInfo,
  );
  const {
    nameRequired,
    zipCodeRequired,
    emailRequired,
    phoneRequired,
    streetNameRequired,
    streetNumberRequired,
  } = useValidationSchemes();

  let validationSchema: SchemaOf<ProfileInformationData> = object().shape({
    name: nameRequired,
    zipCode: zipCodeRequired,
    email: emailRequired,
    phoneNumber: phoneRequired,
    city: string(),
    streetName: streetNameRequired,
    streetNumber: streetNumberRequired,
    countryCode: object(),
  });
  let [initialValues] = useState<ProfileInformationData>({
    name: personalInfo.screenName,
    zipCode: personalInfo.zipCode,
    email: personalInfo.email ?? '',
    phoneNumber: personalInfo.phoneNumber ?? '',
    city: personalInfo.city ?? '',
    streetName: personalInfo.streetName ?? '',
    streetNumber: personalInfo.streetNumber ?? '',
    countryCode:
      personalInfo.countryCode ?? countryCodes.find(p => p.value == '+45'),
  });

  if (!personalInfo) {
    return;
  }

  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}>
        <Grid container rowSpacing={'10px'} columnSpacing={'27px'}>
          <Grid item xs={12}>
            <SubHeaderInputField
              name={'name'}
              placeholder={t('Auth.NamePlaceholder')}
              subHeaderColor={'primary'}
              subHeader={t('Auth.Name')}
            />
          </Grid>
          <Grid item xs={12}>
            <SubHeaderInputField
              name={'email'}
              placeholder={t('Auth.EmailPlaceholder')}
              subHeaderColor={'primary'}
              subHeader={t('Auth.Email')}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container columnSpacing={'27px'}>
              <Grid item xs={5}>
                <SubHeaderInputField
                  name={'countryCode'}
                  placeholder={t('Auth.PhonePlaceholder')}
                  subHeaderColor={'primary'}
                  subHeader={t('Auth.CountryCode')}
                  dropDownValues={countryCodes}
                />
              </Grid>
              <Grid item xs={7}>
                <SubHeaderInputField
                  name={'phoneNumber'}
                  type="number"
                  placeholder={t('Auth.PhonePlaceholder')}
                  subHeaderColor={'primary'}
                  subHeader={t('Auth.Phone')}
                />
              </Grid>
            </Grid>
          </Grid>

          <Box marginTop={2} display={'none'}>
            <Grid item>
            <SubHeaderInputField name={'streetName'} placeholder={''} />
            <SubHeaderInputField name={'streetNumber'} placeholder={''} />
            <SubHeaderInputField name={'zipCode'} placeholder={''} />
            <SubHeaderInputField name={'city'} placeholder={''} />
            </Grid>
          </Box>

          {children}
        </Grid>
      </Formik>
    </>
  );
};
