/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassifiedItem,
    ClassifiedItemFromJSON,
    ClassifiedItemFromJSONTyped,
    ClassifiedItemToJSON,
} from './ClassifiedItem';
import {
    ClientAction,
    ClientActionFromJSON,
    ClientActionFromJSONTyped,
    ClientActionToJSON,
} from './ClientAction';

/**
 * 
 * @export
 * @interface SearchbotMessageResponse
 */
export interface SearchbotMessageResponse {
    /**
     * 
     * @type {string}
     * @memberof SearchbotMessageResponse
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchbotMessageResponse
     */
    threadId?: string | null;
    /**
     * 
     * @type {Array<ClassifiedItem>}
     * @memberof SearchbotMessageResponse
     */
    classifiedItems?: Array<ClassifiedItem> | null;
    /**
     * 
     * @type {ClientAction}
     * @memberof SearchbotMessageResponse
     */
    clientAction?: ClientAction;
}

export function SearchbotMessageResponseFromJSON(json: any): SearchbotMessageResponse {
    return SearchbotMessageResponseFromJSONTyped(json, false);
}

export function SearchbotMessageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchbotMessageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'threadId': !exists(json, 'threadId') ? undefined : json['threadId'],
        'classifiedItems': !exists(json, 'classifiedItems') ? undefined : (json['classifiedItems'] === null ? null : (json['classifiedItems'] as Array<any>).map(ClassifiedItemFromJSON)),
        'clientAction': !exists(json, 'clientAction') ? undefined : ClientActionFromJSON(json['clientAction']),
    };
}

export function SearchbotMessageResponseToJSON(value?: SearchbotMessageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'threadId': value.threadId,
        'classifiedItems': value.classifiedItems === undefined ? undefined : (value.classifiedItems === null ? null : (value.classifiedItems as Array<any>).map(ClassifiedItemToJSON)),
        'clientAction': ClientActionToJSON(value.clientAction),
    };
}

